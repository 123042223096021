import React from 'react'
import checkmark from '../../../../img/main/icon/check-mark.svg'
import classNames from 'classnames'
import styles from './Order.module.css'

const Orders = ({ data, setVisibleModal, setModalData, sendRequest, modalData, sendStatus, pathname }) => {
  const { title_ru, amount, finished } = data

  const onClick = (value) => {	  	
      setModalData(data)	
    handle(value);
  }

  const handle = (order) => {
      if (order == 'all') {
        data.arrayIds.forEach(e => sendStatus({ ...modalData, id: e, restid: parseInt(pathname) }))
      } else {
        sendStatus({ ...modalData, restid: parseInt(pathname), id: data.id })
      }
  }


  return (
    <div className={classNames(finished && 'finished')}>
      <div className={styles.row}>
        <div>
        <span>{amount}x</span>
        <span>{title_ru}</span>
        </div>

          <div className={styles.button_row}>
          <div className={styles.btns}>
            {!finished && <div className={styles.success} onClick={() => handle()}>Да</div>}
            {!finished && <div className={styles.red} onClick={() => handle('all')}>Все</div>}
          </div>
          <div className={styles.danger} 
          onClick={() => onClick('all')}
          >
            &#10006;
          </div>
        </div>
      </div>
      {finished && <img src={checkmark} alt='checkmark' />}
    </div>
  )
}

export default Orders
