import React, { useMemo } from 'react'
import { configureApi } from './constants/configureApi'
import RoutesComponent from './routing/Routes'
import './global.css'
import Paho from 'paho-mqtt'
import { store } from './redux/store'
import { useDispatch } from 'react-redux'
import { authRequestStart } from './redux/actions/Auth'
import sound from './sound/blissful-bell.wav'
import whoosh from './sound/whoosh.mp3'

export const api = configureApi(store)

const App = () => {

  const pathname = window.location.pathname?.split('/')[1]
  const dispatch = useDispatch()
  const host = 'mangocafe.ru'
  // const port = Number(15676)

  const client = useMemo(() => {
    let client = new Paho.Client(`wss://${host}/ws`, `client${Date.now()}`);

    const options = {
      timeout: 3,
      cleanSession: true,
      onSuccess: onConnect,
      onFailure: onFailureFunc,
      keepAliveInterval: 20,
      userName: 'react',
      useSSL: true,
      password: 'LBanrB2ku89q',
    }

    client.onConnectionLost = onConnectionLost
    client.onMessageArrived = onMessageArrived

    client.connect(options)
    return client
  }, [host])

  // called when the client connects
  function onConnect() {
    // Once a connection has been made, make a subscription and send a message.
    var soptions = {
      qos: 0,
    }
    client.subscribe(`/api/orders/${pathname}`, soptions)
    console.log('Subscribe is running')
  }

  // called when the client loses its connection
  function onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log('onConnectionLost:' + responseObject.errorMessage + 'error')
    } else console.log('connected is running')
  }
  // called when a message arrives
  function onMessageArrived(message) {
    if (JSON.parse(message.payloadString).orderStatus == 'opened') {
      var popsound_1 = new Audio(sound)
      popsound_1.load()
      popsound_1.play()
    }
    if ((JSON.parse(message.payloadString).orderId !== null) && (JSON.parse(message.payloadString).orderStatus == 'sent')) {
      if (JSON.parse(message.payloadString).orderStatus == 'sent') {
        var popsound_2 = new Audio(whoosh)
        popsound_2.load()
        popsound_2.play()
      }
      dispatch(authRequestStart(pathname))
    } else if (JSON.parse(message.payloadString).orderStatus == 'close') {
      dispatch(authRequestStart(pathname))
      // console.log('close');
    }
  }

  function onFailureFunc(err) { console.log(err.invocationContext+' '+err.errorCode+':'+err.errorMessage);}

  return <RoutesComponent />
}

export default App
